import React from "react";
import { connect } from 'react-redux';

import { Grid, Header, Message } from 'semantic-ui-react';

import { getFireDepartment, getFireDepartmentTrainers, deleteFireDepartmentTrainer, updateFireDepartmentTrainerStatus } from '../../api/fireDepartmentAPI';

import { UPDATE_FIRE_DEPARTMENT_TRAINERS_UI } from '../../js/actionTypes';
import { PATH_FIRE_DEPARTMENT, PATH_TRAINER, PATH_NEW, EXTERNAL_PATH_FD_ADD_TRAINER_GUIDELINES } from '../../js/paths';

import { sort } from '../../utils/utils';

import ActionButton from '../../components/ActionButton';
import AddButton from '../../components/AddButton';
import InfoButton from '../../components/InfoButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

class FireDepartmentTrainers extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartment(this.props.currentUser.orgId);
    await getFireDepartmentTrainers(this.props.currentUser.orgId);
    this.setState({ loading: false });
  }

  remove = async (id) => {
    this.setState({ loading: 'Removing' });
    await deleteFireDepartmentTrainer(id);
    this.setState({ loading: false });
  }

  changeStatus = async (trid, status, reason) => {
    this.setState({ loading: 'Updating' });
    let data = {
      trainerId: trid,
      type: status,
      reason: reason
    }
    await updateFireDepartmentTrainerStatus(data);
    await this.fetch();
    this.setState({ loading: false });
  }

  handleRemove = async (id) => {
    await this.remove(id);
    await this.fetch();
  }

  handleNew() {
    let canAddTrainee = this.getActiveTrainersCount() < this.props.fireDepartment.maxallowedtrainers;
    if (canAddTrainee) this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_TRAINER }/${ PATH_NEW }`);
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_TRAINERS_UI, fireDepartmentTrainers: this.state.ui });
    });
  }

  getActiveTrainersCount() {
    console.log('*-*-*- getActiveTrainersCount trainers',this.props.trainerList);
    let count = 0;
    for (let trainer of this.props.trainerList) if (trainer.type === 'A') count++;
    console.log('*-*-*- getActiveTrainersCount count',count);
    return count;
  }

  render() {
    let canAddTrainee = this.getActiveTrainersCount() < this.props.fireDepartment.maxallowedtrainers;
    console.log('*-*-*- canAddTrainee',canAddTrainee,this.props.fireDepartment.maxallowedtrainers);
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>
              <Grid>
                <Grid.Column width={ 5 }>
                  <Header.Content>Instructors</Header.Content>
                  <AddButton disabled={ !canAddTrainee } onClick={ this.handleNew.bind(this) } style={{ marginLeft: '1.5rem' }}/>
                  <InfoButton style={{ marginLeft: '0.5rem', marginRight: '-2.5rem' }} pathname={ EXTERNAL_PATH_FD_ADD_TRAINER_GUIDELINES } />
                </Grid.Column>
              </Grid>
            </Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.trainerList.length) {
                return <Message info compact size='large'>
                  You don&apos;t have any instructors.
                </Message>;
              }

              let trainers = sort(this.props.trainerList, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                trainers.reverse();
              }
              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                  { field: 'trainername',           title: 'Name',              width: '3' },
                  { field: 'displayType',           title: 'Status',            width: '1' },
                  { field: 'courses',               title: 'Courses',           width: '7' },
                  { field: 'sortCertDate',          title: 'Certification Date',width: '2' },
                  { field: 'sortDate',              title: 'Registration Date', width: '3' },
                  { field: 'buttons', noSort: true, title: '',                  width: '2' },
              ]}>
                { trainers.map((trainer, index) => (
                  <SortTable.Row key={ trainer.id + "" + index }>
                    <SortTable.Cell>{ trainer.trainername }</SortTable.Cell>
                    <SortTable.Cell>{ trainer.displayType.replace("( )","") }</SortTable.Cell>
                    <SortTable.Cell>{ trainer.courselist }</SortTable.Cell>
                    <SortTable.Cell>{ trainer.displayCertDate }</SortTable.Cell>
                    <SortTable.Cell>{ trainer.displayDate }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      { trainer.type === 'A' && trainer.isTenured &&
                        <ActionButton content='Replace instructor?' onConfirm={ this.changeStatus.bind(this, trainer.trainerId, 'I', ' ') }/>
                      }
                      {
                        trainer.type === 'I' && canAddTrainee &&
                        <ActionButton content='Activate instructor?' onConfirm={ this.changeStatus.bind(this, trainer.trainerId, 'A') }/>
                      }
                      <EditButton pathname={ `/${ PATH_FIRE_DEPARTMENT }/${ PATH_TRAINER }/${ trainer.trainerId }` }/>
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
    // <ActionMultiButton header='Reason for Inactive?'
    //   selectOptions={ ['Retirement', 'Promotion', 'Inability', 'Others'] }
    //   onConfirm={ (reason) => { this.changeStatus(trainer.trainerId, 'I', reason) }}
    // />
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    trainerList: storeState.models.fireDepartmentTrainers,
    fireDepartment: storeState.models.fireDepartment,
    ui: storeState.ui.fireDepartmentTrainers,
  };
}
export default connect(mapStoreStateToProps)(FireDepartmentTrainers);
